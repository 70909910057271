@import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap");

$body-bg: #000;
$body-color: #fff;

$theme-colors: (
  "site-primary": #fff,
  "site-secondary": #191919,
  "site-secondary1": #d1d5e0,
  "site-muted": #292a2d,
  "text-mute": #6e6e7d,
  "site-border-color": #e3e4e6,
  "site-success": rgb(145, 205, 145),
);
@import "node_modules/bootstrap/scss/bootstrap";

.font-family-p {
  font-family: "Questrial", sans-serif;
}
body {
  background-color: $body-bg;
  color: $body-color;
  height: 100%;
  font-family: "homepagebaukasten-bold", sans-serif;
}

::-webkit-scrollbar {
  width: 0.313rem;
  height: 0.313rem;
}
::-webkit-scrollbar-track {
  background: theme-color("site-secondary1");
}
::-webkit-scrollbar-thumb {
  background-color: theme-color("site-muted"); /* color of the scroll thumb */
  border-radius: 1.25rem; /* roundness of the scroll thumb */
}

//header

.header {
  //   font-size: calc(10px + 2vmin);
  padding: 20px 0px;
  .gradient {
    background: linear-gradient(
      90deg,
      rgba(105, 85, 215, 1) 0%,
      rgba(0, 232, 178, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
a {
  color: #fff;
  cursor: pointer;
}
a:hover {
  text-decoration: none !important;
  color: inherit;
}
.img_profile {
  width: 30px;
  height: 30px;
  border-radius: 50px;
}

.cursor-pointer {
  cursor: pointer;
}

.btn_link {
  width: 100px;
  height: 40px;
}
