.App {
  /* text-align: left; */
  background-color: #000000;
  /* height: 100vh; */
  /* font-size: calc(10px + 2vmin); */
  color: white;
  /* max-width: 100%; */
  overflow-x: hidden;

}

/* .App-logo {
  height: 10vmin;
  pointer-events: none;
} */

/* 
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  /* background-color: #000000;
  min-height: 5vh;
  display: flex;
  align-items: left;
  justify-content: left;
  /* font-size: calc(10px + 2vmin); */
  /* color: white; */
}

/* .App-link {
  color: #61dafb;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}